import React from 'react';

const MiniWallet = ({ balance = 0, size = 18 ,streak=0}) => {
    const Coin = ({ size }) => (
          <img 
            src="https://firebasestorage.googleapis.com/v0/b/onehousehelen/o/coin.png?alt=media&token=a306ccbb-52e0-47d4-967a-16a997ee7e92" 
            alt="Coin"
            style={{width: size+'px'}}
          />

      );

  return (
    <div style={styles.walletBar}>
      <div 
        style={{
          ...styles.iconContainer,
          opacity: balance > 1 ? 1 : 0.3
        }}
      >
       <Coin size={size}/>
      </div>
      <span 
        style={{
          ...styles.balanceText,
          fontSize: `${size}px`,
          opacity: balance > 1 ? 1 : 0.3
        }}
      >
        {balance?.toFixed(0) || '0'}
      </span>
    </div>
  );
};

const styles = {
  walletBar: {
    width: "100px",
    backgroundColor: "#140E00",
    borderRadius: "16px",
    padding: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "12px",
    paddingRight: "12px",
    boxSizing: "border-box"
  },
  iconContainer: {
    width: "32px",
    height: "32px",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  balanceText: {
    color: "#FFD470",
    fontFamily: "Inter-Bold, sans-serif",
    fontWeight: "bold"
  }
};

export default MiniWallet;