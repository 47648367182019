import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';

import database from './firebase';
import { getDatabase,ref, onValue ,set} from 'firebase/database'; // Import modular functions
import TopSection from './components/TopSection';
import BottomSection from './components/BottomSection';
import SwybleIntroComponent from './components/SwybleIntroComponent';
import QuestionComponent from './components/QuestionComponent';
import LeaderBoardComponent from './components/LeaderBoardComponent';
import ComingNextComponent from './components/ComingNextComponent';
import BackgroundMusic from './components/BackgroundMusic';
import './App.css';
import LeaderboardList from './components/LeaderBoard';

function ChannelComponent() {
  const { channelId = '_main' } = useParams();
  const [pageState, setPageState] = useState(null);
  const [leaders, setLeaders] = useState([]);
  const [isBlurred, setIsBlurred] = useState(false);
  const appStyle = {
    backgroundImage: pageState?.Question?.topic?.poster 
      ? `url(${pageState.Question.topic.poster})`
      : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backdropFilter: isBlurred ? 'blur(8px)' : 'none',
    transition: 'backdrop-filter 0.3s ease',
  };
  useEffect(() => {
    const db = getDatabase();
    const pageStateRef = ref(db, `rooms/${channelId}`);
    const onDataChange = (snapshot) => {
      const data = snapshot.val();
      if (data && data.players) {
        const playerList = Object.values(data.players)
          .sort((a, b) => (b.score || 0) - (a.score || 0))
          .map((player, index) => ({
            rank: index + 1,
            username: player.name || 'anon',
            score: player.score || 0
          }));
        setLeaders(playerList);
      }
      console.log(data.Question)
    };
    onValue( pageStateRef , onDataChange, (error) => {
      console.error("Firebase data fetch error:", error);
    });
    const unsubscribe = onValue(pageStateRef, (snapshot) => {
      setPageState(snapshot.val());
      setIsBlurred(pageStateRef.mode === 2 || pageStateRef.mode === 3);

    });

    return () => unsubscribe();
  }, [channelId]);

  useEffect(() => {
    console.log(pageState);
  }, [pageState]);

  if (!pageState) return <div>Loading...</div>;

  const renderComponent = () => {
    switch (pageState.mode) {
      case 0:
      case 1:
        return <SwybleIntroComponent   question={pageState.Question}/>;
      case 2:
      case 3:
      case 4:
        return (
          <QuestionComponent
            question={pageState.Question}
            mode={pageState.mode}
          />
        );
      case 5:
        return <LeaderboardList players={leaders}/>;
      default:
        return null;
    }
  };

  return (
    <div className="App" style={appStyle}>
{/*       <TopSection mode={pageState.mode} contest={pageState.Contest}    question={pageState.Question}/> */}
      {/* <BackgroundMusic mode={pageState.mode} /> */}
      <div className="main-content">
        {renderComponent()}
      </div>
      <BottomSection mode={pageState.mode} contest={pageState.Contest}  channelId={channelId}/>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/channel/:channelId" element={<ChannelComponent />} />
        <Route path="/" element={<ChannelComponent />} />
      </Routes>
    </Router>
  );
}

export default App;