// src/components/QuestionComponent.js

import React, { useEffect, useState } from 'react';
import './QuestionComponent.css';
import { CircularProgressbar } from 'react-circular-progressbar';

import { faQuestionCircle, faInfoCircle, faTrophy, faLightbulb, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import ProgressBar from './ProgressBar';
import BackgroundMusic from './BackgroundMusic';

function QuestionComponent({ question, mode }) {
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        // Trigger fade-in when component mounts or mode changes to show question
        if (mode === 2 || mode === 3 || mode === 4) {
            setShowContent(true);
        } else {
            // Trigger fade-out when mode changes to hide question
            setShowContent(false);
        }
    }, [mode]);

    return (
        <div className="question-component">
               {(mode == 2||mode==4 ) && <ProgressBar d={10} />}
            <div className="content-container">
                {(mode === 3 || mode === 2) && <div className={`question-text ${showContent ? 'fade-in' : 'fade-out'}`}>
                    <div>
                    <h2>{question.question_text}</h2>
                    </div>
                </div>}
  
                {(mode === 4) && <div className={`question-text  ${showContent ? 'fade-in' : 'fade-out'}`}>
                    <div style={{alignItems:'center'}}>
                    <img src={"/logo.png"}  alt="Logo" className="center-logo" /> 
                        <h2>{'💡 ' + question.explanation}</h2>
                    </div>
                </div>}
                <div className="options">
                    {question.options.map((option, index) => (
                        <Option
                            key={index}
                            index={index}
                            optionLetter={String.fromCharCode(65 + index)}
                            optionText={option}
                            isCorrect={(mode === 3 || mode === 4) && index === question.answerIndex}
                            showContent={showContent}
                            mode={mode}
                        />
                    ))}
                </div>
            </div>

         

        </div>
    );
}

function Option({ optionLetter, optionText, isCorrect, showContent, index,mode }) {
    return (
        <div
            className={`option ${isCorrect ? 'correct' :(mode==3 || mode==4) ?'wrong':''} ${showContent ? 'fade-in' : 'fade-out'}`}
            style={{ animationDelay: `${index * 0.3}s` }} // Adjust delay as needed
        >
            <div className="option-letter">
                <span>{optionLetter}</span>
            </div>
            <div className="option-text">
                <span>{optionText}</span>
            </div>
        </div>
    );
}

export default QuestionComponent;