import React from 'react';

function ComingNextComponent({ contest }) {
  return (
    <div className="coming-next">
{/*       <div className="left">
        <h2>{contest.Contestname}</h2>
      </div>
      <div className="right">
        <img src={contest.Image} alt={contest.Contestname} />
      </div> */}
    </div>
  );
}

export default ComingNextComponent;