import React from 'react';
import './SwybleIntroComponent.css'; 
function SwybleIntroComponent({ question }) {
  return (
    <div className="swyble-intro center1">
        <img src={"/logo.png"}  alt="Logo" className="center-logo" /> 
        <h1 class="gradient-text">{question.category}</h1>
  <h2>{question.topic.subtitle}</h2>
    </div>
  );
}

export default SwybleIntroComponent;