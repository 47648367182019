import React from 'react';
import { Trophy } from 'lucide-react';
import MiniWallet from './MiniWallet';

const LeaderboardList = ({ players }) => {
  const LeaderboardItem = ({ entry, index }) => (
    <div
      className="leaderboard-item"
      style={{
        animation: `fadeIn 200ms ${index * 100}ms forwards`,
      }}
    >
      <div 
        className="rank-container"
        style={{
          animation: `scaleIn 500ms ${index * 100 + 200}ms forwards`,
        }}
      >
        {entry.rank <= 3 ? (
          <Trophy 
            size={24} 
            color={
              entry.rank === 1 ? "#FFD700" : 
              entry.rank === 2 ? "#C0C0C0" : 
              "#CD7F32"
            }
          />
        ) : (
          <span className="rank-text">{entry.rank}</span>
        )}
      </div>
      
      <div className="user-info">
        <div 
          className="avatar-container"
          style={{
            animation: `scaleIn 500ms ${index * 100 + 300}ms forwards`,
          }}
        >
          <div className="avatar">
            <span>{entry.username.charAt(0).toUpperCase()}</span>
          </div>
        </div>
        
        <span 
          className="username"
          style={{
            animation: `slideIn 500ms ${index * 100 + 400}ms forwards`,
          }}
        >
          {entry.username}
        </span>
      </div>
      
      <div
        className="score"
        style={{
          animation: `scaleIn 500ms ${index * 100 + 500}ms forwards`,
        }}
      ><MiniWallet balance={entry.score}/>
      </div>
    </div>
  );
  
  return (
    <div className="leaderboard-container">
      <style>
        {`
          .leaderboard-container {
            background-color: #010914;
            border-radius: 12px;
            padding: 16px;
            width: 640px;
          }

          .leaderboard-item {
            display: flex;
            align-items: center;
            padding: 12px 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            opacity: 0;
            transform: translateY(20px);
          }

          .leaderboard-item[data-current-user="true"] {
            background-color: rgba(255, 203, 71, 0.1);
            border-radius: 8px;
            margin: 0 -8px;
            padding: 12px 8px;
          }

          .rank-container {
            width: 40px;
            text-align: center;
            opacity: 0;
            transform: scale(0);
          }

          .rank-text {
            color: white;
            font-size: 32px;
            font-weight: bold;
          }

          .user-info {
            flex: 1;
            display: flex;
            align-items: center;
            margin-left: 12px;
          }

          .avatar-container {
            margin-right: 12px;
            opacity: 0;
            transform: scale(0);
            padding:10px;
          }

          .avatar {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: #4a4a4a;
            display: flex;
            align-items: center;
            justify-content: center;
            padding:20px;
          }

          .avatar span {
            color: white;
                    font-size: 32px;
          }

          .username {
            color: white;
             font-size: 36px;
            font-weight: 500;
            opacity: 0;
            transform: translateX(-20px);
          }

          .score {
            background-color: rgba(255, 255, 255, 0.05);
            padding: 4px 8px;
            border-radius: 4px;
            color: white;
            font-size: 48px;
            opacity: 0;
            transform: scale(0.8);
          }

          @keyframes fadeIn {
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          @keyframes scaleIn {
            to {
              opacity: 1;
              transform: scale(1);
            }
          }

          @keyframes slideIn {
            to {
              opacity: 1;
              transform: translateX(0);
            }
          }
        `}
      </style>
      {players.map((player, index) => (
        <LeaderboardItem key={player.rank} entry={player} index={index} />
      ))}
    </div>
  );
};

export default LeaderboardList;