// BottomSection.jsx

import React, { useState, useEffect } from 'react';
import './BottomSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import database from '../firebase';
import { ref, onValue, off } from 'firebase/database';
import ReactCardFlip from 'react-card-flip';

const l = ['A', 'B', 'C', 'D'];


// Define the PlayerType interface (for TypeScript users)
// interface PlayerType {
//   userid: string;
//   name: string;
//   status: string;
//   optionIndexSelected: number | null;
//   time: number;
// }

function BottomSection({ mode, channelId }) {
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    const roomId = channelId || "_main"; // Change as needed
    const playersRef = ref(database, `rooms/${roomId}/players`);

    const handleData = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const playersList = Object.values(data);
        console.log(data);
        setPlayers(playersList);
      } else {
        setPlayers([]);
      }
    };

    onValue(playersRef, handleData, (error) => {
      console.error("Error fetching players:", error);
    });

    // Cleanup listener on unmount
    return () => {
      off(playersRef, 'value', handleData);
    };
  }, []);

  return (
    <div className="bottom-section" style={{flexDirection:'row'}}>
      <div className="center">
        <div className="avatar-container">
          {players.map((player) => {
            const hasAnswered = player.optionIndexSelected !== null && player.optionIndexSelected !== undefined;
            const isCorrect = player.status == "correct";
            const initials = player.name.slice(0, 2).toUpperCase();
            const reveal = (mode == 3 || mode == 4);
            const showCorrect = isCorrect && reveal;
            const showWrong = !isCorrect && hasAnswered && reveal;
            const showNoAnswer = !hasAnswered;
            const showAnswer = hasAnswered && !reveal;
            return (
              <>    <div key={player.userid} className="avatar-wrapper">
                {showCorrect && <ReactCardFlip isFlipped={(mode == 4)} flipDirection="horizontal">
                  <div className="correct-avatar">
                    <span className="avatar-initials">{initials}</span>
                  </div>
                  <div className="correct-avatar">
                    <span className="avatar-initials">{l[player.optionIndexSelected]}</span>
                  </div>
                </ReactCardFlip>}

                {showWrong && <ReactCardFlip isFlipped={(mode == 4)} flipDirection="horizontal">
                  <div className="incorrect-avatar">
                    <span className="avatar-initials">{initials}</span>
                  </div>
                  <div className="incorrect-avatar">
                    <span className="avatar-initials">{l[player.optionIndexSelected]}</span>
                  </div>
                </ReactCardFlip>}
                {(showNoAnswer && !player?.inactive) &&  <div className="unanswered-avatar">
                  <span className="avatar-initials">{initials}</span>
                </div>}
                {(showNoAnswer && player?.inactive) && <div className="inactive-avatar">
                  <span className="avatar-initials">{initials}</span>
                </div>}
                {showAnswer && <ReactCardFlip isFlipped={(mode == 4)} flipDirection="horizontal">
                  <div className="answered-avatar">
                    <span className="avatar-initials">{initials}</span>
                  </div>
                  <div className="answered-avatar">
                    <span className="avatar-initials">{l[player.optionIndexSelected]}</span>
                  </div>
                </ReactCardFlip>}
              </div>
              </>
            );
            /*  return (
               <div key={player.userid} className="avatar-wrapper">
                 {isCorrect && (mode === 3 || mode === 4) ? (
                         <ReactCardFlip isFlipped={(mode==4)} flipDirection="horizontal">
                                  <div className="correct-avatar">
                     <span className="avatar-initials">{initials}</span>
                   </div>
                   <div className="correct-avatar">
                     <span className="avatar-initials">{l[player.optionIndexSelected]}</span>
                   </div>
                       </ReactCardFlip>
 
                 ) : (hasAnswered  &&(mode==3 || mode==4))? (
                   <ReactCardFlip isFlipped={(mode==4)} flipDirection="horizontal">
                     <div className="answered-avatar">
                     <span className="avatar-initials">{initials}</span>
                   </div>
                   <div className="answered-avatar">
                   <span className="avatar-initials">{l[player.optionIndexSelected]}</span>
                   </div>
                   </ReactCardFlip>
                 ) : hasAnswered &&(
                   <div className="unanswered-avatar">
                     <span className="avatar-initials">{initials}</span>
                   </div>
                 )}
               </div>
             ); */
          })}
        </div>
      </div>
    </div>
  );
}

export default BottomSection;